const initialState = {
	siren: "",
	resultat: "",
	critere: "le Siren",
	identifiant: null,
	isLoadingSiren: false,
	redirectionSiren: false,
	erreur: false,
};

const fonctionPourSiren = (state = initialState, action) => {
	if (action.type === "SIREN") {
		return {
			...state,
			siren: action.siren,
		};
	}

	if (action.type === "CRITERE") {
		return {
			...state,
			critere: action.critere,
		};
	}

	if (action.type === "IDENTIFIANT") {
		return {
			...state,
			identifiant: action.identifiant,
		};
	}

	if (action.type === "SIREN_FETCH_GET_EN_COURS") {
		return {
			...state,
			isLoadingSiren: true,
			redirectionSiren: false,
		};
	}

	if (action.type === "RAZ_REDIRECTION_SIREN") {
		return {
			...state,
			isLoadingSiren: false,
			redirectionSiren: false,
		};
	}

	if (action.type === "INITIAL_STATE_SIREN") {
		return {
			...state,
			...initialState,
		};
	}

	if (action.type === "SIREN_FETCH_GET_ERREUR") {
		return {
			...state,
			resultat: action.err,
			isLoadingSiren: false,
			redirectionSiren: false,
			erreur: true,
		};
	}

	if (action.type === "SIREN_FETCH_GET_SUCCESS") {
		return {
			...state,
			resultat: action.res,
			isLoadingSiren: false,
			redirectionSiren: true,
			erreur: false,
		};
	}

	return state;
};

export default fonctionPourSiren;
