import { combineReducers } from "redux";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import sirenReducer from "store/siren/sirenReducer";
import siretReducer from "store/siret/siretReducer";
import siretsSirenOuSiretReducer from "store/siretsSirenOuSiret/siretsSirenOuSiretReducer";
import departementReducer from "store/departement/departementReducer";
import authentificationReducer from "store/authentification/authentificationReducer";
import storageSession from "redux-persist/lib/storage/session";
import { getConfiguration } from "configuration";
import { configureStore } from "@reduxjs/toolkit";

const loggerMiddleware = createLogger();

const isNotProduction = () => {
	return getConfiguration()
		.then((config) => {
			return config.ENVIRONNEMENT !== "production";
		})
		.catch(() => {
			console.error("isNotProduction configuration");
		});
};

let middlewareValeurs = [];
if (isNotProduction) {
	middlewareValeurs = middlewareValeurs = (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(loggerMiddleware);
} else {
	middlewareValeurs = middlewareValeurs = (getDefaultMiddleware) => getDefaultMiddleware();
}

const persistConfig = {
	key: "root",
	storage: storageSession,
};

const reducers = combineReducers({
	...{ sirenReducer },
	...{ siretReducer },
	...{ siretsSirenOuSiretReducer },
	...{ departementReducer },
	...{ authentificationReducer },
});

const persistedReducers = persistReducer(persistConfig, reducers);

export const creerStore = () => {
	const store = configureStore({
		reducer: persistedReducers,
		middleware: middlewareValeurs,
		devTools: isNotProduction,
	});
	const persistor = persistStore(store);
	return { store, persistor };
};
