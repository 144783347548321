import { getConfiguration } from "configuration";

const getUrlChatbox = () => {
	return getConfiguration()
		.then((config) => {
			return config.CHATBOX_URL;
		})
		.catch((err) => {
			console.error("erreur lors de la récupération de l'url chatbox", err);
		});
};

const getUrlChatboxScript = () => {
	return getUrlChatbox()
		.then((url) => {
			return url + "/webcomponent/wlcp-chatbox.js";
		})
		.catch((err) => {
			console.error("erreur lors de la récupération de l'url chatbox", err);
		});
};

const getUrlChatboxCSS = () => {
	return getUrlChatbox()
		.then((url) => {
			return url + "/webcomponent/wlcp-chatbox.css";
		})
		.catch((err) => {
			console.error("erreur lors de la récupération de l'url chatbox", err);
		});
};

export { getUrlChatbox, getUrlChatboxScript, getUrlChatboxCSS };
