import { fetchResultat } from "api/fetch";

const setSiren = (siren) => {
	return { type: "SIREN", siren };
};

const setCritere = (critere) => {
	return { type: "CRITERE", critere };
};

const setIdentifiant = (identifiant) => {
	return { type: "IDENTIFIANT", identifiant };
};

const requeteEnCoursSiren = () => {
	return { type: "SIREN_FETCH_GET_EN_COURS" };
};

const razRedirectionSiren = () => {
	return { type: "RAZ_REDIRECTION_SIREN" };
};

const setInitialStateSiren = () => {
	return { type: "INITIAL_STATE_SIREN" };
};

const fetchSiren = (siren, telephone) => {
	return (dispatch) => {
		dispatch(requeteEnCoursSiren());
		return fetchResultat("/identification/siren/" + siren + "?telephone=" + telephone).then(
			(res) => dispatch({ type: "SIREN_FETCH_GET_SUCCESS", res }),
			(err) => dispatch({ type: "SIREN_FETCH_GET_ERREUR", err })
		);
	};
};

export {
	setSiren,
	setCritere,
	setIdentifiant,
	requeteEnCoursSiren,
	razRedirectionSiren,
	setInitialStateSiren,
	fetchSiren,
};
