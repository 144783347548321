import { fetchResultat } from "api/fetch";

const setSiret = (siret) => {
	return { type: "SIRET", siret };
};

const requeteEnCoursSiret = () => {
	return { type: "SIRET_FETCH_GET_EN_COURS" };
};

const razRedirectionSiret = () => {
	return { type: "RAZ_REDIRECTION_SIRET" };
};

const setInitialStateSiret = () => {
	return { type: "INITIAL_STATE_SIRET" };
};

const fetchSiret = (siret, telephone) => {
	return (dispatch) => {
		dispatch(requeteEnCoursSiret());
		return fetchResultat("/identification/siret/" + siret + "?telephone=" + telephone).then(
			(res) => dispatch({ type: "SIRET_FETCH_GET_SUCCESS", res }),
			(err) => dispatch({ type: "SIRET_FETCH_GET_ERREUR", err })
		);
	};
};

export { setSiret, requeteEnCoursSiret, razRedirectionSiret, setInitialStateSiret, fetchSiret };
