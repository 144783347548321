import { useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import withRouter from "utils/withRouter.js";
import GoAndFocus from "utils/GoAndFocus";
import { setInitialStateSiretsSirenOuSiret } from "store/siretsSirenOuSiret/siretsSirenOuSiretActionCreator";
import { setInitialStateSiret } from "store/siret/siretActionCreator";
import { setInitialStateSiren } from "store/siren/sirenActionCreator";

const Header = () => {
	const dispatch = useDispatch();
	const currentPath = useLocation().pathname;
	const goToContenu = useCallback(() => GoAndFocus("contenu"), []);
	const goToSirenSiret = useCallback(() => GoAndFocus("SirenSiret"), []);
	const goToEntrep = useCallback(() => GoAndFocus("entreprise"), []);
	const goToEtab = useCallback(() => GoAndFocus("etablissement"), []);
	const goToListeEtab = useCallback(() => GoAndFocus("liste"), []);
	const reinitialisationState = useCallback(() => {
		dispatch(setInitialStateSiren());
		dispatch(setInitialStateSiret());
		dispatch(setInitialStateSiretsSirenOuSiret());
	}, [dispatch]);

	return (
		<header role="banner" id="top">
			<div className="container">
				<nav role="navigation" aria-label="Accès rapide" className="rapide">
					<ul>
						<li>
							<Link to="#contenu" onClick={goToContenu}>
								<span className="sr-only">Aller au </span>Contenu
							</Link>
						</li>

						{currentPath === "/" && (
							<li>
								<Link to="#SirenSiret" onClick={goToSirenSiret}>
									<span className="sr-only">Aller à la </span>Recherche Siren/Siret
								</Link>
							</li>
						)}
						{(currentPath === "/siege" ||
							currentPath === "/etablissement" ||
							currentPath === "/liste") && (
							<li>
								<Link to="#entreprise" onClick={goToEntrep}>
									<span className="sr-only">Aller aux informations </span>Entreprise
								</Link>
							</li>
						)}
						{(currentPath === "/siege" || currentPath === "/etablissement") && (
							<li>
								<Link to="#etablissement" onClick={goToEtab}>
									<span className="sr-only">Aller aux informations </span>&Eacute;tablissement
								</Link>
							</li>
						)}
						{currentPath === "/liste" && (
							<li>
								<Link to="#liste" onClick={goToListeEtab}>
									Liste des établissements
								</Link>
							</li>
						)}
					</ul>
				</nav>

				<div className="entete">
					<div className="logo">
						<img
							src={process.env.PUBLIC_URL + "/images/logo_rf.png"}
							alt="République Française"
							className="logoRF"
						/>
						<img
							src={process.env.PUBLIC_URL + "/images/logo_insee.png"}
							alt="Insee, Mesurer pour comprendre"
							className="logoInsee"
						/>
						<NavLink to="/" onClick={reinitialisationState}>
							Avis de situation au répertoire Sirene
						</NavLink>
					</div>
				</div>
			</div>
		</header>
	);
};

export default withRouter(Header);
