import { fetchResultat } from "api/fetch";

const setRang = (rang) => {
	return { type: "RANG", rang };
};

const setStatut = (statut) => {
	return { type: "STATUT", statut };
};

const setDep = (dep) => {
	return { type: "DEP", dep };
};

const razRedirectionSiretsSirenOuSiret = () => {
	return { type: "RAZ_REDIRECTION_siretsSirenOuSiret" };
};

const setInitialStateSiretsSirenOuSiret = () => {
	return { type: "INITIAL_STATE_siretsSirenOuSiret" };
};

const requeteEnCoursSiretsSirenOuSiret = () => {
	return { type: "siretsSirenOuSiret_FETCH_GET_EN_COURS" };
};

const fetchSiretsSirenOuSiret = (siret, rang, dep, statut) => {
	return (dispatch) => {
		dispatch(requeteEnCoursSiretsSirenOuSiret());
		return fetchResultat(
			"/identification/sirets/sirenOuSiret/" +
				siret +
				"?departement=" +
				dep +
				"&etat=" +
				statut +
				"&rangPremierEtablissement=" +
				rang
		).then(
			(res) => dispatch({ type: "siretsSirenOuSiret_FETCH_GET_SUCCESS", res }),
			(err) => dispatch({ type: "siretsSirenOuSiret_FETCH_GET_ERREUR", err })
		);
	};
};

export {
	setRang,
	setStatut,
	setDep,
	razRedirectionSiretsSirenOuSiret,
	setInitialStateSiretsSirenOuSiret,
	requeteEnCoursSiretsSirenOuSiret,
	fetchSiretsSirenOuSiret,
};
