import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RoutageProperties } from "properties/RoutagePropertiesLazy";
import Header from "components/communs/gabarit/BlocHeader";
import Footer from "components/communs/gabarit/BlocFooter";
import ScrollToTop from "hooks/useScrollToTop";
import RetourEnHaut from "hooks/useRetourEnHaut";
import NavFilAriane from "components/communs/gabarit/NavFilAriane";
import LazyLoading from "components/communs/gabarit/LazyLoading";

const App = () => {
	return (
		<>
			<BrowserRouter basename="/">
				<ScrollToTop />
				<Header />
				<div className="container">
					<main role="main" id="contenu" tabIndex={-1}>
						<NavFilAriane />
						<Suspense fallback={<LazyLoading />}>
							<Routes>
								{RoutageProperties.map((route) => (
									<Route key={route.path} path={route.path} element={route.Component} />
								))}
							</Routes>
						</Suspense>
					</main>
					<RetourEnHaut />
				</div>
				<Footer />
			</BrowserRouter>
		</>
	);
};

export default App;
