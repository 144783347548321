import React from "react";

const LazyLoading = () => {
	return (
		<div className="lazyLoading">
			<p className="sr-only">Chargement...</p>
			<span className="loader"></span>
		</div>
	);
};

export default LazyLoading;
