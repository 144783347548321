const initialState = {
	utilisateur: "",
	token: "",
	tokenExpired: false,
	fromFranceConnect: false,
};

const fonctionAuthentification = (state = initialState, action) => {
	if (action.type === "LOGIN") {
		const previousUser = state.utilisateur;
		return {
			...state,
			utilisateur: action.payload.user,
			token: action.payload.idToken,
			tokenExpired: false,
			fromFranceConnect: previousUser !== action.payload.user,
		};
	}

	if (action.type === "LOGOUT") {
		return { ...state, utilisateur: undefined, token: undefined };
	}

	if (action.type === "SET_TOKEN_EXPIRED") {
		return { ...state, tokenExpired: action.payload.tokenExpired };
	}

	if (action.type === "SET_FROM_FRANCE_CONNECT") {
		return { ...state, fromFranceConnect: action.payload.fromFranceConnect };
	}

	return state;
};

export default fonctionAuthentification;
