import React from "react";
import { getUrlChatbox, getUrlChatboxCSS, getUrlChatboxScript } from "./chatbox.utils";

const Chatbox = () => {
	React.useEffect(() => {
		getChatboxScript();
		getChatboxCSS();
		chatboxConfig();
	}, []);
	return <wlcp-chatbox></wlcp-chatbox>;
};

const getChatboxScript = async () => {
	const baliseScript = document.createElement("script");
	baliseScript.src = await getUrlChatboxScript();
	document.head.appendChild(baliseScript);
};

const getChatboxCSS = async () => {
	const baliseLink = document.createElement("link");
	baliseLink.rel = "stylesheet";
	baliseLink.href = await getUrlChatboxCSS();
	document.head.appendChild(baliseLink);
};

const chatboxConfig = async () => {
	const urlChatbox = await getUrlChatbox();
	window.WLCP_CONFIG = {
		bot: {
			url: urlChatbox,
		},
	};
};

export default Chatbox;
