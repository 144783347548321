import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<footer role="contentinfo" id="piedDePage">
			<div id="listeDeLiens">
				<div className="container">
					<div className="flex">
						<div className="item-33">
							<p>Téléprocédure Sirene</p>
							<ul>
								<li>
									<a
										href="https://www.insee.fr/fr/information/7456564"
										target="_blank"
										rel="noreferrer noopener"
										title="Modification du statut de diffusion (nouvelle fenêtre)"
									>
										Modification du statut de diffusion
									</a>
								</li>
							</ul>
						</div>
						<div className="item-33">
							<p>Services SIRENE</p>
							<ul>
								<li>
									<a
										href="https://api.insee.fr/catalogue/site/themes/wso2/subthemes/insee/pages/item-info.jag?name=Sirene&amp;version=V3&amp;provider=insee"
										target="_blank"
										rel="noreferrer noopener"
										title="API Sirene (nouvelle fenêtre)"
									>
										API Sirene
									</a>
								</li>
								<li>
									<a
										href="https://www.sirene.fr/"
										target="_blank"
										rel="noreferrer noopener"
										title="Base de données Sirene.fr (nouvelle fenêtre)"
									>
										Base de données Sirene.fr
									</a>
								</li>
							</ul>
						</div>
						<div className="item-33">
							<p>S'informer</p>
							<ul>
								<li>
									<a
										href="https://www.insee.fr/fr/information/1972043"
										target="_blank"
										rel="noreferrer noopener"
										title="Connaître et comprendre le répertoire Sirene (nouvelle fenêtre)"
									>
										<strong>Connaître et comprendre le répertoire Sirene</strong>
									</a>
								</li>
								<li>
									<a
										href="https://www.insee.fr/fr/information/2406147"
										target="_blank"
										rel="noreferrer noopener"
										title="Consulter les nomenclatures d’activités françaises (nouvelle fenêtre)"
									>
										Consulter les nomenclatures d’activités françaises
									</a>
								</li>

								<li>
									<a
										href="https://www.insee.fr/fr/information/2410945"
										target="_blank"
										rel="noreferrer noopener"
										title="Nous contacter (nouvelle fenêtre)"
									>
										Nous contacter
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div id="mentionsObligatoires">
				<ul>
					<li>
						<Link to="mentions-legales">Mentions légales et crédits</Link>
					</li>
					<li>
						<Link to="accessibilite">Accessibilité : Totalement conforme</Link>
					</li>
					<li>
						<Link to="securite">Sécurité</Link>
					</li>
					<li>
						<Link to="plan">Plan de site</Link>
					</li>
				</ul>
			</div>
		</footer>
	);
};

export default Footer;
