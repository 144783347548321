import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { RoutageProperties } from "properties/RoutagePropertiesLazy";

const useScrollToTop = () => {
	const currentPath = useLocation().pathname;
	const pageEnCours = RoutageProperties.filter((item) => item.path === currentPath);
	const title = Object.keys(pageEnCours)
		.map((obj) => pageEnCours[obj].filAriane)
		.toString();
	const maRef = useRef();

	useEffect(() => {
		window.scrollTo(0, 0);
		maRef.current.focus();
	}, [currentPath]);

	return (
		<div ref={maRef} tabIndex={-1} className="sr-only">
			Page {title}
		</div>
	);
};

export default useScrollToTop;
