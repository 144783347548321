const initialState = {
	resultat: "",
	rang: 0,
	statut: "",
	dep: "",
	isLoadingSiretsSirenOuSiret: false,
	redirectionSiretsSirenOuSiret: false,
	erreur: false,
};

const fonctionPourSirenOuSiret = (state = initialState, action) => {
	if (action.type === "RANG") {
		return {
			...state,
			rang: action.rang,
		};
	}

	if (action.type === "STATUT") {
		return {
			...state,
			statut: action.statut,
		};
	}

	if (action.type === "DEP") {
		return {
			...state,
			dep: action.dep,
		};
	}

	if (action.type === "RAZ_REDIRECTION_siretsSirenOuSiret") {
		return {
			...state,
			isLoadingSiretsSirenOuSiret: false,
			redirectionSiretsSirenOuSiret: false,
		};
	}

	if (action.type === "INITIAL_STATE_siretsSirenOuSiret") {
		return {
			...state,
			...initialState,
		};
	}

	if (action.type === "siretsSirenOuSiret_FETCH_GET_EN_COURS") {
		return {
			...state,
			isLoadingSiretsSirenOuSiret: true,
			redirectionSiretsSirenOuSiret: false,
		};
	}

	if (action.type === "siretsSirenOuSiret_FETCH_GET_ERREUR") {
		return {
			...state,
			resultat: action.err,
			isLoadingSiretsSirenOuSiret: false,
			redirectionSiretsSirenOuSiret: false,
			erreur: true,
		};
	}

	if (action.type === "siretsSirenOuSiret_FETCH_GET_SUCCESS") {
		return {
			...state,
			resultat: action.res,
			isLoadingSiretsSirenOuSiret: false,
			redirectionSiretsSirenOuSiret: true,
			erreur: false,
		};
	}
	return state;
};

export default fonctionPourSirenOuSiret;
