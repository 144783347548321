const initialState = {
	departement: "",
	resultat: "",
	isLoadingDepartement: false,
	erreur: false,
};

const fonctionPourDepartement = (state = initialState, action) => {
	if (action.type === "DEPARTEMENT") {
		return {
			...state,
			departement: action.departement,
		};
	}

	if (action.type === "DEPARTEMENT_FETCH_GET_EN_COURS") {
		return {
			...state,
			isLoadingDepartement: true,
		};
	}

	if (action.type === "DEPARTEMENT_FETCH_GET_ERREUR") {
		return {
			...state,
			resultat: action.err,
			isLoadingDepartement: false,
			erreur: true,
		};
	}

	if (action.type === "DEPARTEMENT_FETCH_GET_SUCCESS") {
		return {
			...state,
			resultat: action.res,
			isLoadingDepartement: false,
			erreur: false,
		};
	}

	return state;
};

export default fonctionPourDepartement;
