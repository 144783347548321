import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";

const useRetourEnHaut = () => {
	const [voirBouton, setVoirBouton] = useState(false);

	useEffect(() => {
		const scrollBoutonVisibilite = () => {
			window.scrollY > 300 ? setVoirBouton(true) : setVoirBouton(false);
		};
		window.addEventListener("scroll", scrollBoutonVisibilite);
		return () => {
			window.removeEventListener("scroll", scrollBoutonVisibilite);
		};
	}, []);

	return (
		<>
			{voirBouton && (
				<HashLink smooth to="#top" className="retourEnHaut">
					Haut de page
				</HashLink>
			)}
		</>
	);
};

export default useRetourEnHaut;
