import React, { useCallback } from "react";
import { RoutageProperties } from "properties/RoutagePropertiesLazy";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setInitialStateSiretsSirenOuSiret } from "store/siretsSirenOuSiret/siretsSirenOuSiretActionCreator";
import { setInitialStateSiret } from "store/siret/siretActionCreator";
import { setInitialStateSiren } from "store/siren/sirenActionCreator";

const NavFilAriane = () => {
	const dispatch = useDispatch();
	const currentPath = useLocation().pathname;
	const pageEnCours = RoutageProperties.filter((item) => item.path === currentPath);

	const libelle = Object.keys(pageEnCours)
		.map((obj) => pageEnCours[obj].filAriane)
		.toString();

	const reinitialisationState = useCallback(() => {
		dispatch(setInitialStateSiren());
		dispatch(setInitialStateSiret());
		dispatch(setInitialStateSiretsSirenOuSiret());
	}, [dispatch]);

	return (
		<>
			{currentPath !== "/" && (
				<nav role="navigation" aria-label="Vous êtes ici : " className="ariane">
					<ol>
						<li>
							<NavLink to="/" onClick={reinitialisationState}>
								Accueil
							</NavLink>
						</li>
						{libelle && <li aria-current="page">{libelle}</li>}
					</ol>
				</nav>
			)}
		</>
	);
};

export default NavFilAriane;
