import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { creerStore } from "store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Chatbox from "components/chatbox/Chatbox";

const storeRedux = creerStore();

createRoot(document.getElementById("root")).render(
	<Provider store={storeRedux.store}>
		<PersistGate loading={null} persistor={storeRedux.persistor}>
			<App />
			<Chatbox />
		</PersistGate>
	</Provider>
);
