const initialState = {
	siret: "",
	resultat: "",
	isLoadingSiret: false,
	redirectionSiret: false,
	erreur: false,
};

const fonctionPourSiret = (state = initialState, action) => {
	if (action.type === "SIRET") {
		return {
			...state,
			siret: action.siret,
		};
	}

	if (action.type === "SIRET_FETCH_GET_EN_COURS") {
		return {
			...state,
			isLoadingSiret: true,
			redirectionSiret: false,
		};
	}

	if (action.type === "RAZ_REDIRECTION_SIRET") {
		return {
			...state,
			isLoadingSiret: false,
			redirectionSiret: false,
		};
	}

	if (action.type === "INITIAL_STATE_SIRET") {
		return {
			...state,
			...initialState,
		};
	}

	if (action.type === "SIRET_FETCH_GET_ERREUR") {
		return {
			...state,
			resultat: action.err,
			isLoadingSiret: false,
			redirectionSiret: false,
			erreur: true,
		};
	}

	if (action.type === "SIRET_FETCH_GET_SUCCESS") {
		return {
			...state,
			resultat: action.res,
			isLoadingSiret: false,
			redirectionSiret: true,
			erreur: false,
		};
	}

	return state;
};

export default fonctionPourSiret;
