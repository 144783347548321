const config = `${window.location.origin}/configuration.json`;

let urlConfig;

export const getConfiguration = () =>
	urlConfig !== undefined
		? Promise.resolve(urlConfig)
		: fetch(config).then((res) => {
				urlConfig = res.clone().json();
				return res.clone().json();
		  });
