import { lazy } from "react";
const Accueil = lazy(() => import("components/accueil/Accueil"));
const MentionsLegales = lazy(() => import("components/piedDePage/MentionsLegales"));
const Accessibilite = lazy(() => import("components/piedDePage/Accessibilite"));
const Securite = lazy(() => import("components/piedDePage/Securite"));
const ResultatSirenSiege = lazy(() =>
	import("components/demarcheAvisitu/resultatSirenSiege/ResultatSirenSiege")
);
const ResultatSiret = lazy(() => import("components/demarcheAvisitu/resultatSiret/ResultatSiret"));
const ResultatListeEtablissements = lazy(() =>
	import("components/demarcheAvisitu/resultatListeEtablissements/ResultatListeEtablissements")
);
const PageErreurTechnique = lazy(() => import("components/communs/erreurs/PageErreurTechnique"));
const PageNotFound = lazy(() => import("components/communs/erreurs/PageNotFound"));
const PlanDeSite = lazy(() => import("components/piedDePage/PlanDeSite"));

const RoutageProperties = [
	{
		path: "/",
		filAriane: "Accueil",
		Component: <Accueil />,
	},
	{
		path: "/siege",
		filAriane: "Télécharger l'avis de situation de l'établissement siège",
		Component: <ResultatSirenSiege />,
	},
	{
		path: "/etablissement",
		filAriane: "Télécharger l'avis de situation de l'établissement",
		Component: <ResultatSiret />,
	},
	{
		path: "/liste",
		filAriane: "Rechercher dans la liste des établissements de l'entreprise",
		Component: <ResultatListeEtablissements />,
	},
	{
		path: "/mentions-legales",
		filAriane: "Mentions légales et crédit",
		Component: <MentionsLegales />,
	},
	{
		path: "/accessibilite",
		filAriane: "Accessibilité",
		Component: <Accessibilite />,
	},
	{
		path: "/securite",
		filAriane: "Sécurité",
		Component: <Securite />,
	},
	{
		path: "/plan",
		filAriane: "Plan du site",
		Component: <PlanDeSite />,
	},
	{
		path: "/erreur",
		filAriane: "Erreur technique",
		Component: <PageErreurTechnique />,
	},
	{
		path: "*",
		filAriane: "Page non trouvée",
		Component: <PageNotFound />,
	},
];

export { RoutageProperties };
